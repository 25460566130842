import React, { useEffect, useState } from "react";

const MenuList = () => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    // Fetch menu items from localStorage whenever the component renders
    const storedItems =
      JSON.parse(localStorage.getItem("sandboxMenuItems")) || [];
    setMenuItems(storedItems);
  }, []); // This ensures it re-renders when called by the parent component

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold text-primary mb-4">Restaurant Menu</h2>
      {menuItems.length === 0 ? (
        <p className="text-gray-500 text-center">No menu items available.</p>
      ) : (
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {menuItems.map((item, index) => (
            <div
              key={index}
              className="border rounded-lg overflow-hidden shadow-sm transition-transform transform hover:scale-105"
            >
              {item.image ? (
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-40 object-cover"
                />
              ) : (
                <div className="w-full h-40 bg-gray-100 flex items-center justify-center text-gray-500">
                  No Image
                </div>
              )}
              <div className="p-4">
                <h3 className="text-lg font-semibold text-primary truncate">
                  {item.name}
                </h3>
                <p className="text-sm text-gray-600 mt-1 truncate">
                  {item.description || "No description provided."}
                </p>
                <p className="text-secondary font-bold mt-2">
                  ${parseFloat(item.price).toFixed(2)}
                </p>
                <p className="text-gray-500 text-xs mt-1">
                  Category:{" "}
                  <span className="text-gray-700">
                    {item.category || "Uncategorized"}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuList;
