import React from "react";
import { Link } from "react-router-dom";
import ChefHatAnimation from "./ChefHatAnimation";

const Hero = () => {
  return (
    <section
      id="home"
      className="relative bg-white py-24 sm:py-28 overflow-hidden"
    >
      {/* Background Overlay Gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-primary via-transparent to-secondary opacity-10 pointer-events-none"></div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col-reverse md:flex-row items-center">
        {/* Hero Text Section */}
        <div className="text-center md:text-left md:w-1/2 z-10 mt-10 md:mt-0">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl leading-tight">
            Take your restaurant to <br /> the next level
          </h1>
          <p className="mt-6 text-base sm:text-lg text-gray-700 leading-relaxed sm:leading-8">
            Making it simple to run your restaurant by creating a digital menu,
            and letting your customers order online via QR code
          </p>
          <div className="mt-8 flex justify-center md:justify-start space-x-4">
            <Link
              to="/getting-started"
              className="bg-primary text-white py-3 px-6 sm:px-8 rounded-full text-sm sm:text-lg font-semibold shadow-lg hover:shadow-2xl hover:scale-105 hover:bg-secondary transition duration-300 transform"
            >
              Get Started
            </Link>
            <Link
              to="/request-demo"
              className="text-primary border border-primary py-3 px-6 sm:px-8 rounded-full text-sm sm:text-lg font-semibold hover:text-white hover:bg-secondary hover:border-secondary hover:shadow-md transition duration-300 transform"
            >
              Demo Today
            </Link>
          </div>
        </div>

        {/* Hero Animation Section */}
        <div className="md:w-1/2 relative z-10">
          <ChefHatAnimation />
        </div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute top-10 left-10 w-40 h-40 sm:w-56 sm:h-56 bg-accent1 rounded-full opacity-5 blur-2xl pointer-events-none"></div>
      <div className="absolute bottom-10 right-10 w-40 h-40 sm:w-56 sm:h-56 bg-secondary rounded-full opacity-5 blur-2xl pointer-events-none"></div>
    </section>
  );
};

export default Hero;
