import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const GettingStarted = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    businessName: "",
    uenNumber: "",
  });

  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <section className="py-16">
          <div className="max-w-3xl mx-auto px-6 lg:px-8 text-left">
            <h1 className="text-4xl font-bold text-primary mb-6">
              Get Started with FireTable
            </h1>
            <p className="text-lg text-gray-700 mb-12">
              Fill out the form below, and our team will contact you to get
              started.
            </p>

            {/* Form */}
            <form
              action="https://submit-form.com/hIa3trxw3"
              method="POST"
              className="bg-white shadow-xl rounded-lg p-8 space-y-6"
            >
              {/* Name */}
              <div>
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  required
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-primary focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  required
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-primary focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              {/* Phone */}
              <div>
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  required
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-primary focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              {/* Business Name */}
              <div>
                <label
                  htmlFor="businessName"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Business Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  placeholder="Enter your business name"
                  required
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-primary focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              {/* UEN Number */}
              <div>
                <label
                  htmlFor="uenNumber"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  UEN Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="uenNumber"
                  name="uenNumber"
                  placeholder="Enter your UEN number"
                  required
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-primary focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              {/* reCAPTCHA */}
              <div className="flex justify-center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleCaptchaChange}
                />
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  disabled={!captchaVerified}
                  className={`w-full py-3 rounded-lg font-semibold shadow-lg transition-all duration-300 ${
                    captchaVerified
                      ? "bg-primary text-white hover:shadow-xl hover:bg-secondary"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default GettingStarted;
