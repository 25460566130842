import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CustomerOrder = ({ cartItems }) => {
  const [step, setStep] = useState(1);
  const [customerInfo, setCustomerInfo] = useState({
    fullName: "",
    email: "",
    phone: "",
  });
  const [comments, setComments] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // Popup state
  const navigate = useNavigate();

  const totalCost = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const handleProceed = () => {
    if (
      step === 2 &&
      (!customerInfo.fullName || !customerInfo.email || !customerInfo.phone)
    ) {
      alert("Please fill out all required fields.");
      return;
    }
    setStep((prevStep) => prevStep + 1);
  };

  const handleConfirmOrder = () => {
    // Simulate order confirmation
    setShowSuccessPopup(true); // Show the success popup
  };

  const handlePopupClose = () => {
    setShowSuccessPopup(false);
    navigate("/"); // Redirect to the home page
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold text-primary mb-6">Checkout</h1>

      <div className="flex items-center justify-center mb-8">
        <div
          className={`flex items-center ${
            step >= 1 ? "text-primary" : "text-gray-400"
          }`}
        >
          <span
            className={`w-8 h-8 flex items-center justify-center rounded-full ${
              step >= 1 ? "bg-secondary text-white" : "bg-gray-300"
            }`}
          >
            1
          </span>
          <span className="ml-2 text-sm font-semibold">Review Order</span>
        </div>
        <div
          className={`flex-grow border-t-2 mx-2 ${
            step > 1 ? "border-secondary" : "border-gray-300"
          }`}
        />
        <div
          className={`flex items-center ${
            step >= 2 ? "text-primary" : "text-gray-400"
          }`}
        >
          <span
            className={`w-8 h-8 flex items-center justify-center rounded-full ${
              step >= 2 ? "bg-secondary text-white" : "bg-gray-300"
            }`}
          >
            2
          </span>
          <span className="ml-2 text-sm font-semibold">Customer Info</span>
        </div>
        <div
          className={`flex-grow border-t-2 mx-2 ${
            step > 2 ? "border-accent1" : "border-gray-300"
          }`}
        />
        <div
          className={`flex items-center ${
            step === 3 ? "text-primary" : "text-gray-400"
          }`}
        >
          <span
            className={`w-8 h-8 flex items-center justify-center rounded-full ${
              step === 3 ? "bg-accent1 text-white" : "bg-gray-300"
            }`}
          >
            3
          </span>
          <span className="ml-2 text-sm font-semibold">Confirm Order</span>
        </div>
      </div>

      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6">
        {step === 1 && (
          <div>
            <h2 className="text-xl font-bold text-primary mb-4">
              Review Your Order
            </h2>
            <ul className="space-y-4">
              {cartItems.map((item) => (
                <li key={item.id} className="flex justify-between">
                  <div>
                    <h3 className="font-semibold">{item.name}</h3>
                    <p className="text-sm text-gray-500">
                      {item.quantity} x ${item.price.toFixed(2)}
                    </p>
                  </div>
                  <p>${(item.quantity * item.price).toFixed(2)}</p>
                </li>
              ))}
            </ul>
            <div className="flex justify-between font-bold mt-6 border-t pt-4">
              <span>Total</span>
              <span>${totalCost.toFixed(2)}</span>
            </div>
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Add comments (optional)"
              className="w-full mt-4 p-2 border rounded-lg"
            />
            <button
              onClick={handleProceed}
              className="w-full mt-6 py-3 bg-secondary text-white rounded-lg hover:bg-accent1"
            >
              Proceed to Customer Info
            </button>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2 className="text-xl font-bold text-primary mb-4">
              Customer Information
            </h2>
            <div className="space-y-4">
              <input
                type="text"
                name="fullName"
                value={customerInfo.fullName}
                onChange={handleInputChange}
                placeholder="Full Name"
                className="w-full p-2 border rounded-lg"
              />
              <input
                type="email"
                name="email"
                value={customerInfo.email}
                onChange={handleInputChange}
                placeholder="Email Address"
                className="w-full p-2 border rounded-lg"
              />
              <input
                type="tel"
                name="phone"
                value={customerInfo.phone}
                onChange={handleInputChange}
                placeholder="Phone Number"
                className="w-full p-2 border rounded-lg"
              />
            </div>
            <button
              onClick={handleProceed}
              className="w-full mt-6 py-3 bg-secondary text-white rounded-lg hover:bg-accent1"
            >
              Confirm Information
            </button>
          </div>
        )}

        {step === 3 && (
          <div>
            <h2 className="text-xl font-bold text-primary mb-4">
              Confirm Order
            </h2>
            <p className="mb-4">
              Please review your order details before confirming:
            </p>
            <ul className="space-y-4">
              {cartItems.map((item) => (
                <li key={item.id} className="flex justify-between">
                  <div>
                    <h3 className="font-semibold">{item.name}</h3>
                    <p className="text-sm text-gray-500">
                      {item.quantity} x ${item.price.toFixed(2)}
                    </p>
                  </div>
                  <p>${(item.quantity * item.price).toFixed(2)}</p>
                </li>
              ))}
            </ul>
            <div className="flex justify-between font-bold mt-6 border-t pt-4">
              <span>Total</span>
              <span>${totalCost.toFixed(2)}</span>
            </div>
            <button
              onClick={handleConfirmOrder}
              className="w-full mt-6 py-3 bg-secondary text-white rounded-lg hover:bg-accent1"
            >
              Confirm Order
            </button>
          </div>
        )}
      </div>

      {/* Success Popup */}
      {showSuccessPopup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <h2 className="text-2xl font-bold text-primary mb-4">
              Order Successful!
            </h2>
            <p className="text-gray-700 mb-6">
              Thank you for your order. We appreciate your business!
            </p>
            <button
              onClick={handlePopupClose}
              className="px-6 py-3 bg-secondary text-white rounded-lg hover:bg-accent1 transition"
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerOrder;
