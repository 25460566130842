import React from "react";
import Navbar from "./components/Navbar";
// @Blogs
import BlogUI from "./BlogList/BlogUI";
import Footer from "./components/Footer";

const Blog = () => {
  return (
    <div>
      <Navbar /> {/* Navbar for consistent navigation */}
      <BlogUI /> {/* Display Work in Progress component */}
      <Footer />
    </div>
  );
};

export default Blog;
