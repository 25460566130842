import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const WhyNeedUs = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
      <Navbar />

      {/* Blog Content */}
      <main className="bg-gray-50 flex-grow py-12 px-8 md:px-16 space-y-16">
        <div className="max-w-3xl mx-auto">
          {/* Header Section */}
          <section id="introduction" className="space-y-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              Why Your Restaurant Needs FireTable
            </h1>
            <img
              src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?w=400&auto=format&fit=crop&q=60"
              alt="Restaurant Technology for Enhanced Efficiency"
              className="w-full h-[350px] object-cover rounded-lg shadow-lg"
            />
            <p className="text-base text-gray-700 leading-relaxed mt-6">
              Managing a restaurant in today’s fast-paced industry comes with
              its challenges. Balancing operations, enhancing customer
              experiences, and keeping costs under control are just some of the
              hurdles. FireTable is designed to help restaurant owners
              streamline operations, reduce inefficiencies, and elevate the
              dining experience.
            </p>
            <p className="text-base text-gray-700 leading-relaxed">
              This blog explores the benefits and features of FireTable and
              explains how it can be a valuable addition to your restaurant
              business.
            </p>
          </section>
          <br />

          {/* Benefits Section */}
          <section id="benefits" className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-900">
              Benefits of Using FireTable
            </h2>
            <p className="text-base text-gray-700 leading-relaxed">
              FireTable offers practical solutions for restaurant owners aiming
              to enhance efficiency and provide a seamless dining experience.
              Key benefits include:
            </p>
            <ul className="list-disc list-inside text-base text-gray-700 space-y-3 pl-6">
              <li>
                <strong>Streamline Operations:</strong> Simplify order
                management with real-time updates, ensuring your team operates
                efficiently.
              </li>
              <li>
                <strong>Enhanced Customer Experience:</strong> Allow diners to
                access digital menus and track their orders for a modern and
                transparent experience.
              </li>
              <li>
                <strong>Cost Savings:</strong> Reduce overhead by eliminating
                printed menus and embracing an eco-friendly, digital-first
                approach.
              </li>
              <li>
                <strong>Data-Driven Insights:</strong> Use analytics to identify
                trends, optimize your offerings, and drive revenue growth.
              </li>
            </ul>
          </section>
          <br />

          {/* Features Section */}
          <section id="features" className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-900">
              Key Features of FireTable
            </h2>
            <p className="text-base text-gray-700 leading-relaxed">
              FireTable provides a comprehensive suite of features tailored to
              the unique needs of restaurants. Each feature has been designed
              with usability and business growth in mind.
            </p>
            <ul className="list-disc list-inside text-base text-gray-700 space-y-3 pl-6">
              <li>
                <strong>Customizable Digital Menus:</strong> Create and update
                your menu effortlessly, ensuring customers always see the latest
                options.
              </li>
              <li>
                <strong>Real-Time Order Tracking:</strong> Improve communication
                between front-of-house and kitchen teams with live updates on
                orders.
              </li>
              <li>
                <strong>Analytics Dashboard:</strong> Access valuable insights
                into sales trends and customer preferences to make data-backed
                decisions.
              </li>
              <li>
                <strong>Eco-Friendly Approach:</strong> Reduce waste by
                eliminating the need for printed menus, contributing to a
                greener environment.
              </li>
            </ul>
          </section>
          <br />

          {/* Conclusion Section */}
          <section id="conclusion" className="space-y-6">
            <h2 className="text-2xl font-semibold text-gray-900">Conclusion</h2>
            <p className="text-base text-gray-700 leading-relaxed">
              FireTable is a practical solution for restaurants looking to
              improve efficiency, enhance the customer experience, and make
              smarter business decisions. By adopting digital menus and
              real-time analytics, restaurants can stay competitive in a rapidly
              changing industry.
            </p>
            <p className="text-base text-gray-700 leading-relaxed">
              Whether you're managing a small café or a large restaurant,
              FireTable has tools designed to help you succeed. Explore how
              FireTable can empower your business and transform your operations
              today.
            </p>
          </section>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default WhyNeedUs;
