import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa"; // Import arrow icon from react-icons

const WhyNeedUsThumb = () => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 space-y-4 hover:shadow-lg transition">
      {/* Blog Thumbnail Image */}
      <img
        src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?w=500&auto=format&fit=crop&q=60"
        alt="Why Your Restaurant Needs FireTable"
        className="w-full h-auto rounded-lg"
      />
      {/* Blog Title */}
      <h2 className="text-xl font-bold text-gray-900">
        Why Your Restaurant Needs FireTable
      </h2>
      {/* Blog Summary */}
      <p className="text-gray-700">
        Discover how FireTable can revolutionize your restaurant operations
        through digital menus, real-time analytics, and enhanced efficiency.
      </p>
      {/* Read More Button */}
      <Link
        to="/blog/why-your-restaurant-needs-firetable"
        className="flex items-center text-primary font-medium hover:text-secondary transition"
      >
        Read More
        <FaArrowRight className="ml-2" /> {/* Arrow Icon */}
      </Link>
    </div>
  );
};

export default WhyNeedUsThumb;
