import React, { useState } from "react";
import { ArrowDown2 } from "iconsax-react"; // Iconsax icons

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "Can customers pay directly through the online menu page?",
      answer:
        "No, the current version only allows for real-time orders to be captured, not payment.",
    },
    {
      question:
        "How do I make this software work with my existing POS systems?",
      answer:
        "You can have the FireTable software open on any device, preferably an iPad or laptop, view the orders in real-time, and then key them into your POS. This eliminates the need to send waitstaff to tables.",
    },
    {
      question:
        "Does this software integrate with any finance system in the backend?",
      answer:
        "We do not directly integrate with finance systems; however, we allow CSV reports to be generated from the analytics dashboard.",
    },
    {
      question:
        "Can I use this product standalone if I don't have any existing POS systems in place?",
      answer:
        "This is only applicable if the business only accepts cash and does not have any integrated payment terminals.",
    },
    {
      question:
        "Is my business required to have Wi-Fi available in order to use this?",
      answer:
        "It is recommended to have Wi-Fi; however, you can still use mobile data in the event that Wi-Fi is not readily available.",
    },
    {
      question: "What if I don't own any Laptop or iPad devices?",
      answer:
        "You can contact our team and discuss how we could work on provisioning a device. Additional charges may apply.",
    },
  ];

  return (
    <section
      id="faq" // Added id for smooth scrolling
      className="bg-gradient-to-b from-white to-gray-50 py-20 mt-12"
    >
      <div className="max-w-5xl mx-auto px-6 sm:px-8 lg:px-10">
        {/* Header */}
        <div className="text-center mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl">
            Frequently Asked Questions
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Find answers to some of the most common questions about FireTable.
          </p>
        </div>

        {/* Accordion */}
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`bg-white shadow-xl rounded-2xl transition-all duration-500 ${
                activeIndex === index ? "transform scale-105 shadow-2xl" : ""
              }`}
            >
              {/* Question */}
              <button
                className="w-full px-8 py-5 flex justify-between items-center text-gray-900 font-medium text-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary transition"
                onClick={() => toggleAccordion(index)}
              >
                <span>{faq.question}</span>
                <ArrowDown2
                  size="24"
                  className={`transition-transform duration-500 ${
                    activeIndex === index ? "rotate-180" : "rotate-0"
                  }`}
                />
              </button>

              {/* Answer */}
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  activeIndex === index ? "max-h-screen px-8 py-5" : "max-h-0"
                }`}
              >
                <p className="text-gray-700 text-base leading-relaxed">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
