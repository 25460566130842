import React from "react";
import { MenuBoard, Chart, Timer1, Tree } from "iconsax-react"; // Iconsax icons

const Feature = () => {
  const features = [
    {
      icon: <MenuBoard size="30" variant="Bold" className="text-primary" />,
      title: "Digital Menus",
      description: (
        <p>
          Create and manage digital menus
          <br /> that your customers can access via QR codes.
        </p>
      ),
    },
    {
      icon: <Chart size="30" variant="Bold" className="text-primary" />,
      title: "Real-Time Analytics",
      description: (
        <p>
          Get sales insights and understand
          <br /> customer preferences with data-driven decisions.
        </p>
      ),
    },
    {
      icon: <Timer1 size="30" variant="Bold" className="text-primary mt-4" />,
      title: <h3 className="mt-4">Efficient Operations</h3>,
      description: (
        <p>
          Streamline order-taking and reduce wait times
          <br /> with a more efficient workflow.
        </p>
      ),
    },
    {
      icon: <Tree size="30" variant="Bold" className="text-primary mt-4" />,
      title: <h3 className="mt-4">Eco-Friendly Solution</h3>,
      description: (
        <p>
          Minimize paper use and adopt a sustainable, <br />
          digital-first approach for your business.
        </p>
      ),
    },
  ];

  return (
    <section
      id="features" // Added id for smooth scrolling
      className="bg-white py-12 mt-12"
    >
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-6">
        {/* Header */}
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl">
            Why Choose FireTable?
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Discover how FireTable helps restaurants achieve efficiency and
            sustainability with cutting-edge tools.
          </p>
        </div>

        {/* Features Grid */}
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 ml-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group bg-gray-50 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 p-8 border-l-4 border-primary transform hover:-translate-y-2"
            >
              {/* Icon and Title */}
              <div className="flex items-center space-x-3">
                <div>{feature.icon}</div>
                <h3 className="text-lg font-medium text-gray-900">
                  {feature.title}
                </h3>
              </div>
              {/* Description */}
              <p className="mt-4 text-sm text-gray-600 group-hover:text-primary transition duration-300">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Feature;
