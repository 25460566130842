import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Feature from "./components/Feature";
import Pricing from "./components/Pricing";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import Hiw from "./components/Hiw";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Feature />
      <Hiw />
      <Pricing />
      <FAQ />
      <Footer />
    </div>
  );
};

export default Home;
