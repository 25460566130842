import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views when the route changes
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  // Helper function to log custom events
  const logEvent = (eventName, eventParams) => {
    if (window.gtag) {
      window.gtag("event", eventName, eventParams);
    }
  };

  // Example usage of logEvent
  useEffect(() => {
    logEvent("custom_init", { status: "Google Analytics Initialized" });
  }, []);

  return (
    <Helmet>
      {/* Google Analytics Global Site Tag */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=G-L46CCD0LTH`}
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-L46CCD0LTH');
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
