import React from "react";
import { AiFillLinkedin } from "react-icons/ai"; // React Icons for LinkedIn
import logo from "../assets/FireLabsLogo.png"; // Same logo as Navbar
import { Link } from "react-router-dom"; // Import Link for navigation

const Footer = () => {
  return (
    <footer className="bg-primary text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          {/* Company Info */}
          <div className="md:col-span-2">
            <div className="flex items-center space-x-4">
              <div>
                <img
                  src={logo} // Using the same logo from Navbar
                  alt="FireTable Logo"
                  className="h-10 w-auto"
                />
              </div>
              <h2 className="text-xl font-bold">FireTable</h2>
            </div>
            <p className="mt-4 text-gray-300">
              60 Paya Lebar Road #07-54
              <br /> Paya Lebar Square
              <br /> Singapore 409051
            </p>
          </div>

          {/* Company Links */}
          <div>
            <h3 className="text-lg font-semibold text-gray-100 mb-4">
              Company
            </h3>
            <ul className="space-y-2 text-gray-300">
              <li>
                <a href="#blog" className="hover:text-accent1 transition">
                  Blog
                </a>
              </li>
              <li>
                <a href="#customers" className="hover:text-accent1 transition">
                  Customers
                </a>
              </li>
              <li>
                <a href="#firestore" className="hover:text-accent1 transition">
                  Fire Store
                </a>
              </li>
              <li>
                <a href="#firepos" className="hover:text-accent1 transition">
                  Fire POS
                </a>
              </li>
            </ul>
          </div>

          {/* Features Links */}
          <div>
            <h3 className="text-lg font-semibold text-gray-100 mb-4">
              Features
            </h3>
            <ul className="space-y-2 text-gray-300">
              <li>
                <a href="#press" className="hover:text-accent1 transition">
                  Press
                </a>
              </li>
              <li>
                <a
                  href="#releasenotes"
                  className="hover:text-accent1 transition"
                >
                  Release Notes
                </a>
              </li>
            </ul>
          </div>

          {/* Resources Links */}
          <div>
            <h3 className="text-lg font-semibold text-gray-100 mb-4">
              Resources
            </h3>
            <ul className="space-y-2 text-gray-300">
              <li>
                <a href="#support" className="hover:text-accent1 transition">
                  Support
                </a>
              </li>
              <li>
                <a href="#guide" className="hover:text-accent1 transition">
                  Guide
                </a>
              </li>
              <li>
                <a
                  href="https://linkedin.com"
                  className="hover:text-accent1 transition"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Separator */}
        <div className="border-t border-gray-700 my-8"></div>

        {/* Bottom Section */}
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          {/* Legal Links */}
          <div className="text-gray-300 text-sm">
            <Link to="/pc" className="hover:text-accent1 transition">
              Privacy Policy
            </Link>{" "}
            /{" "}
            <Link to="/tc" className="hover:text-accent1 transition">
              Terms
            </Link>{" "}
          </div>

          {/* LinkedIn Icon */}
          <div className="flex space-x-4">
            <a href="https://linkedin.com" className="hover:text-accent1">
              <AiFillLinkedin size="24" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
