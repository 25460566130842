import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom"; // Use HashRouter

// @ Home Pages
import Home from "./pages/Home";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import GettingStarted from "./pages/GettingStarted";
import NotFound from "./pages/Notfound";
import Sandbox from "./pages/Sandbox";

// @ Components
import ScrollToTop from "./pages/components/ScrollToTop"; // Import ScrollToTop component

// @ Footer Pages
import Blog from "./pages/Blog";
import Customers from "./pages/Customers";
import FireStore from "./pages/FireStore";
import FirePOS from "./pages/FirePOS";
import Press from "./pages/Press";
import ReleaseNotes from "./pages/ReleaseNotes";
import Support from "./pages/Support";
import Guide from "./pages/Guide";

// @ Blog List
import WhyNeedUs from "./pages/BlogList/WhyNeedUs"; // Import WhyNeedUs blog component

// @ Marketing Forms
import PotentialCustomer from "./pages/PotentialCustomer";

// @ Analytics
import GoogleAnalytics from "./pages/analytics/GoogleAnalytics";
import CookieBanner from "./pages/analytics/CookieBanner";

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Ensures smooth scrolling on route changes */}
      <div className="App">
        <Routes>
          {/* Define routes here */}
          <Route path="/" element={<Home />} />
          <Route path="/tc" element={<TermsAndConditions />} />
          <Route path="/pc" element={<PrivacyPolicy />} />
          <Route path="/getting-started" element={<GettingStarted />} />
          <Route path="/demo" element={<Sandbox />} />
          <Route path="/blog" element={<Blog />} />
          <Route
            path="/blog/why-your-restaurant-needs-firetable"
            element={<WhyNeedUs />}
          />{" "}
          {/* Individual Blog Route */}
          <Route path="/customers" element={<Customers />} />
          <Route path="/firestore" element={<FireStore />} />
          <Route path="/firepos" element={<FirePOS />} />
          <Route path="/press" element={<Press />} />
          <Route path="/releasenotes" element={<ReleaseNotes />} />
          <Route path="/support" element={<Support />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="*" element={<NotFound />} />
          {/* @ Markeing Routes */}
          <Route path="/request-demo" element={<PotentialCustomer />} />
        </Routes>
        {/* @ Analytics Routes */}
        <GoogleAnalytics />
        <CookieBanner />
      </div>
    </Router>
  );
}

export default App;
