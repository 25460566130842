import React from "react";
import Lottie from "lottie-react";
import chefHatAnimation from "../animations/scanner.json"; // Adjust the path if needed

const ChefHatAnimation = () => {
  return (
    <div className="flex justify-center items-center w-full">
      {/* Lottie Animation */}
      <Lottie
        animationData={chefHatAnimation}
        loop={true}
        className="w-60 h-60 sm:w-80 sm:h-80 md:w-96 md:h-96 lg:w-[32rem] lg:h-[32rem]"
      />
    </div>
  );
};

export default ChefHatAnimation;
