import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa"; // For hamburger and close icons
import logo from "../assets/FireLabsLogo.png"; // Ensure the path to the logo is correct
import { HashLink as RouterLink } from "react-router-hash-link"; // Hash Link for navigation

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="sticky top-0 bg-white py-4 mt-4 z-50 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        {/* Logo Section */}
        <div className="flex items-center">
          <img src={logo} alt="FireTable Logo" className="h-10 w-auto" />
          <span className="ml-3 text-xl font-extrabold text-primary tracking-wide">
            FireTable
          </span>
        </div>

        {/* Desktop Links Section */}
        <div className="hidden md:flex space-x-8">
          <RouterLink
            to="/#"
            className="text-gray-700 font-medium hover:text-primary transition duration-200 cursor-pointer"
          >
            Home
          </RouterLink>
          <RouterLink
            to="/#features"
            className="text-gray-700 font-medium hover:text-primary transition duration-200 cursor-pointer"
          >
            Features
          </RouterLink>
          <RouterLink
            to="/#pricing"
            className="text-gray-700 font-medium hover:text-primary transition duration-200 cursor-pointer"
          >
            Pricing
          </RouterLink>
          <RouterLink
            to="/#faq"
            className="text-gray-700 font-medium hover:text-primary transition duration-200 cursor-pointer"
          >
            FAQ
          </RouterLink>
        </div>

        {/* Get Started Button */}
        <div className="hidden md:block">
          <RouterLink
            to="/getting-started"
            className="bg-primary text-white py-2 px-5 rounded-full font-semibold shadow-lg hover:shadow-xl hover:bg-secondary transition duration-300 cursor-pointer"
          >
            Get Started
          </RouterLink>
        </div>

        {/* Hamburger Menu Icon for Mobile */}
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-primary text-2xl focus:outline-none"
          >
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-white z-50 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300`}
      >
        {/* Close Button */}
        <div className="flex justify-between items-center px-6 py-4">
          <img src={logo} alt="FireTable Logo" className="h-8 w-auto" />
          <button
            onClick={toggleMenu}
            className="text-primary text-2xl focus:outline-none"
          >
            <FaTimes />
          </button>
        </div>

        {/* Mobile Links */}
        <div className="flex flex-col items-center mt-10 space-y-6">
          <RouterLink
            to="/#"
            onClick={toggleMenu}
            className="text-gray-700 text-lg font-medium hover:text-primary transition duration-200 cursor-pointer"
          >
            Home
          </RouterLink>
          <RouterLink
            to="/#features"
            onClick={toggleMenu}
            className="text-gray-700 text-lg font-medium hover:text-primary transition duration-200 cursor-pointer"
          >
            Features
          </RouterLink>
          <RouterLink
            to="/#pricing"
            onClick={toggleMenu}
            className="text-gray-700 text-lg font-medium hover:text-primary transition duration-200 cursor-pointer"
          >
            Pricing
          </RouterLink>
          <RouterLink
            to="/#faq"
            onClick={toggleMenu}
            className="text-gray-700 text-lg font-medium hover:text-primary transition duration-200 cursor-pointer"
          >
            FAQ
          </RouterLink>
          <RouterLink
            to="/getting-started"
            onClick={toggleMenu}
            className="bg-primary text-white py-2 px-5 rounded-full font-semibold shadow-lg hover:shadow-xl hover:bg-secondary transition duration-300 cursor-pointer"
          >
            Get Started
          </RouterLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
