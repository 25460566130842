import React, { useState } from "react";
import { Link } from "react-router-dom";

const Pricing = () => {
  const [billingCycle, setBillingCycle] = useState("monthly");

  // Google Analytics Logging Helper
  const logEvent = (eventName, eventParams) => {
    if (window.gtag) {
      window.gtag("event", eventName, eventParams);
    }
  };

  // Updated pricing plans data
  const plans = [
    {
      name: "Basic",
      monthlyPrice: "$25/mo",
      annualPrice: "$240/yr", // 25 * 12 with 20% off
      description: "Ideal for small businesses to get started.",
      features: [
        "Analytics",
        "Customer Dashboard",
        "Automatic Menu Builder",
        "Real Time Order Tracker",
        "6 QR Code Menus Generated",
      ],
      discount: "20% off if purchased annually", // Updated discount text
    },
    {
      name: "Standard",
      monthlyPrice: "$50/mo",
      annualPrice: "$480/yr", // 50 * 12 with 20% off
      description: "Best for medium-sized restaurants.",
      features: [
        "Analytics",
        "Customer Dashboard",
        "Automatic Menu Builder",
        "Real Time Order Tracker",
        "22 QR Code Menus Generated",
        { text: "Custom Branding", isAdditional: true }, // Updated feature
        { text: "Half Setup Support", isAdditional: true }, // New feature
      ],
      discount: "20% off if purchased annually", // Updated discount text
    },
    {
      name: "Premium",
      monthlyPrice: "$99/mo",
      annualPrice: "$1188/yr", // 99 * 12
      description: "Perfect for scaling your operations.",
      features: [
        "Analytics",
        "Customer Dashboard",
        "Automatic Menu Builder",
        "Real Time Order Tracker",
        "54 QR Code Menus Generated",
        { text: "Custom Branding", isAdditional: true }, // Updated feature
        { text: "Full Setup Support", isAdditional: true }, // New feature
      ],
      discount: "20% off if purchased annually", // Updated discount text
    },
  ];

  return (
    <section
      id="pricing"
      className="bg-gradient-to-b from-white to-gray-50 py-16"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        {/* Header */}
        <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl">
          Pricing Plans
        </h2>
        <p className="mt-4 text-gray-600 text-base">
          Choose a plan that fits your restaurant's needs.
        </p>

        {/* Toggle Button */}
        <div className="mt-8 flex justify-center items-center">
          <button
            className={`px-4 py-2 text-lg transition-all rounded-l-full ${
              billingCycle === "monthly"
                ? "text-white bg-primary font-bold"
                : "text-primary bg-gray-200"
            }`}
            onClick={() => {
              setBillingCycle("monthly");
              logEvent("billing_cycle_toggle", { billingCycle: "monthly" });
            }}
          >
            Monthly
          </button>
          <button
            className={`px-4 py-2 text-lg transition-all rounded-r-full ${
              billingCycle === "annual"
                ? "text-white bg-primary font-bold"
                : "text-primary bg-gray-200"
            }`}
            onClick={() => {
              setBillingCycle("annual");
              logEvent("billing_cycle_toggle", { billingCycle: "annual" });
            }}
          >
            Annual
          </button>
        </div>

        {/* Pricing Grid */}
        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`relative bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 p-8 border ${
                index === 1
                  ? "border-primary bg-gray-50 scale-105 transform shadow-2xl"
                  : "border-gray-200"
              } ${index === 1 ? "hover:scale-110" : "hover:-translate-y-3"}`}
              onClick={() => {
                logEvent("plan_click", { planName: plan.name });
              }}
            >
              {/* Plan Title */}
              <h3 className="text-xl font-semibold text-gray-900">
                {plan.name}
              </h3>

              {/* Price */}
              <p className="mt-4 text-4xl font-bold text-primary">
                {billingCycle === "monthly"
                  ? plan.monthlyPrice
                  : plan.annualPrice}
              </p>

              {/* Discount Text for Annual Plans */}
              {billingCycle === "annual" && (
                <p className="text-sm text-gray-500 mt-1">{plan.discount}</p>
              )}

              {/* Description */}
              <p className="mt-2 text-gray-600 text-sm">{plan.description}</p>

              {/* Features */}
              <ul className="mt-6 space-y-4 text-sm text-gray-600">
                {plan.features.map((feature, i) => (
                  <li key={i} className="flex items-center">
                    <span
                      className={`font-bold text-lg ${
                        feature.isAdditional ? "text-secondary" : "text-primary"
                      }`}
                    >
                      ✓
                    </span>
                    <span className="ml-3">
                      {feature.isAdditional ? feature.text : feature}
                    </span>
                  </li>
                ))}
              </ul>

              {/* CTA Button */}
              <div className="mt-8">
                <Link
                  to="/getting-started"
                  className={`w-full block text-center py-3 px-5 rounded-full font-semibold shadow-md transition-all duration-300 ${
                    index === 1
                      ? "bg-secondary text-white hover:bg-primary"
                      : "bg-primary text-white hover:bg-secondary"
                  }`}
                  onClick={() => {
                    logEvent("get_started_click", { planName: plan.name });
                  }}
                >
                  Get Started
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
