import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
const PrivacyPolicy = () => {
  return (
    <div>
      {/* Navbar */}
      <Navbar />

      {/* Privacy Policy Content */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">
            Privacy Policy
          </h1>
          <p className="text-gray-700 text-lg leading-relaxed mb-8">
            At FireTable, we take your privacy seriously. This Privacy Policy
            outlines how we collect, use, and protect your personal information.
            By using our services, you consent to the practices described in
            this policy.
          </p>

          {/* Information We Collect */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Information We Collect
            </h2>
            <p className="text-gray-700 leading-relaxed">
              FireTable collects personal information that you voluntarily
              provide to us when you sign up, interact with our platform, or
              contact our support team. This may include:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-3 mt-4">
              <li>
                Name and contact details, including email and phone number.
              </li>
              <li>
                Business details, such as restaurant name, address, and table
                configuration.
              </li>
              <li>
                Usage data, such as login activity and order management logs.
              </li>
            </ul>
          </div>

          {/* Stripe Payment Handling */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Payment Handling and Security
            </h2>
            <p className="text-gray-700 leading-relaxed">
              FireTable uses Stripe for all payment processing. Stripe is a
              trusted and industry-leading payment processor, ensuring that your
              payment information is handled securely. All sensitive payment
              details, such as card numbers, expiry dates, and CVV codes, are
              encrypted and processed directly by Stripe. FireTable does not
              store or process any payment details on our servers.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              For more information about Stripe's security practices, please
              visit Stripe's official article:{" "}
              <a
                href="https://stripe.com/docs/security"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary underline"
              >
                Is Stripe secure?
              </a>
            </p>
          </div>

          {/* How We Use Your Information */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              How We Use Your Information
            </h2>
            <p className="text-gray-700 leading-relaxed">
              The information we collect is used solely to provide, maintain,
              and improve our services. Specifically, we use your information
              to:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-3 mt-4">
              <li>
                Enable the core functionalities of FireTable, such as real-time
                orders and analytics.
              </li>
              <li>Provide customer support and troubleshoot issues.</li>
              <li>Enhance user experience and optimize system performance.</li>
            </ul>
          </div>

          {/* Commitment to Data Protection */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Commitment to Data Protection
            </h2>
            <p className="text-gray-700 leading-relaxed">
              FireTable does not sell, rent, or share your personal information
              with third parties. We do not use any third-party software to
              track or monetize customer data. All data is stored securely and
              protected against unauthorized access. However, in the unlikely
              event of a security compromise, affected users will be notified
              promptly, and necessary measures will be taken to mitigate risks.
            </p>
          </div>

          {/* Data Retention */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Data Retention
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We retain your data for as long as necessary to provide our
              services or comply with legal obligations. You may request
              deletion of your data at any time by contacting our support team.
            </p>
          </div>

          {/* Your Rights */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Your Rights
            </h2>
            <p className="text-gray-700 leading-relaxed">
              As a user of FireTable, you have the following rights:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-3 mt-4">
              <li>
                Access and review the personal information we hold about you.
              </li>
              <li>
                Request correction or deletion of inaccurate or outdated
                information.
              </li>
              <li>Withdraw consent for data processing where applicable.</li>
            </ul>
          </div>

          {/* Changes to this Privacy Policy */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Changes to this Privacy Policy
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We may update this Privacy Policy periodically to reflect changes
              in our practices or legal requirements. We encourage you to review
              this policy regularly to stay informed about how we protect your
              data.
            </p>
          </div>

          {/* Contact Information */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Contact Information
            </h2>
            <p className="text-gray-700 leading-relaxed">
              If you have any questions about this Privacy Policy, please
              contact us at:
            </p>
            <p className="text-gray-700 mt-2">
              Email:{" "}
              <a href="mailto:support@firetable.com" className="text-primary">
                support@firetable.com
              </a>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
