import React from "react";
import Navbar from "./components/Navbar";
import WIP from "./components/WIP"; // Import the WIP component
import Footer from "./components/Footer";

const Support = () => {
  return (
    <div>
      <Navbar /> {/* Navbar for consistent navigation */}
      <WIP /> {/* Display Work in Progress component */}
      <Footer />
    </div>
  );
};

export default Support;
