import React from "react";
import Navbar from "./components/Navbar";
import RlseNt1 from "./ReleaseNotes/RlseNt-1";
import Footer from "./components/Footer";

const ReleaseNotes = () => {
  return (
    <div>
      <Navbar /> {/* Navbar for consistent navigation */}
      <RlseNt1 /> 
      <Footer />
    </div>
  );
};

export default ReleaseNotes;
