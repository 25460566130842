import React, { useState } from "react";
import AddMenuItem from "./SandBoxComponents/AddMenuItem";
import MenuList from "./SandBoxComponents/MenuList";
import PurchaseOrder from "./SandBoxComponents/PurchaseOrder";
import CustomerOrder from "./SandBoxComponents/CustomerOrder";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const Sandbox = () => {
  const [refresh, setRefresh] = useState(false);
  const [showCustomerSide, setShowCustomerSide] = useState(false); // Toggle between sides
  const [cart, setCart] = useState([]); // Shared cart state for total linking
  const [hasAccess, setHasAccess] = useState(false); // Check for key access
  const [demoKey, setDemoKey] = useState(""); // Input for demo key

  // Toggle menu refresh
  const handleRefresh = () => setRefresh(!refresh);

  React.useEffect(() => {
    // Check if the demo key exists in localStorage
    const storedKey = localStorage.getItem("demoKey");
    if (storedKey === "XQEFNR") {
      setHasAccess(true);
    }
  }, []);

  const handleKeySubmit = (e) => {
    e.preventDefault();
    if (demoKey === "XQEFNR") {
      localStorage.setItem("demoKey", demoKey);
      setHasAccess(true);
    } else {
      alert("Invalid Demo Key");
    }
  };

  // Render the key entry form if access is not granted
  if (!hasAccess) {
    return (
      <div className="min-h-screen flex items-center justify-center p-6">
        <div className="max-w-md w-full bg-white shadow-md rounded-lg p-8">
          <h1 className="text-2xl font-bold text-primary text-center mb-4">
            Enter Demo Key
          </h1>
          <form onSubmit={handleKeySubmit} className="space-y-4">
            <div>
              <label
                htmlFor="demoKey"
                className="block text-sm font-semibold text-gray-700"
              >
                Demo Key
              </label>
              <input
                type="text"
                id="demoKey"
                value={demoKey}
                onChange={(e) => setDemoKey(e.target.value)}
                placeholder="Enter your demo key"
                required
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-primary text-white py-3 rounded-lg font-semibold hover:bg-secondary transition"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      <main className="p-6 md:p-10 flex-grow">
        {/* Sandbox Header */}
        <div className="text-center mb-10">
          <h1 className="text-3xl font-bold text-primary">Demo</h1>
          <p className="text-gray-600 mt-2">
            Explore the{" "}
            <span className="text-secondary font-semibold">
              Restaurant Side
            </span>{" "}
            to manage the menu and the{" "}
            <span className="text-accent1 font-semibold">Customer Side</span> to
            place orders.
          </p>
        </div>

        {/* Restaurant Side */}
        {!showCustomerSide && (
          <section className="mb-12">
            <h2 className="text-2xl font-semibold text-secondary mb-6">
              Restaurant Side
            </h2>
            <p className="text-gray-700 mb-4">
              Manage your menu items and update the list in real-time.
            </p>
            <div className="grid gap-6 md:grid-cols-2">
              <AddMenuItem onAddItem={handleRefresh} />
              <MenuList key={refresh} />
            </div>
          </section>
        )}

        {/* Customer Side */}
        {showCustomerSide && (
          <section className="mb-12">
            <h2 className="text-2xl font-semibold text-accent1 mb-6">
              Customer Side
            </h2>
            <p className="text-gray-700 mb-4">
              Browse the menu, add items to your cart, and place an order.
            </p>
            <div className="grid gap-6 md:grid-cols-2">
              <PurchaseOrder cartItems={cart} setCartItems={setCart} />
              <CustomerOrder cartItems={cart} />
            </div>
          </section>
        )}

        {/* Toggle Button */}
        <div className="flex justify-center mt-8">
          <button
            onClick={() => setShowCustomerSide(!showCustomerSide)}
            className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-secondary transition duration-200"
          >
            {showCustomerSide
              ? "Back to Restaurant Side"
              : "Switch to Customer Side"}
          </button>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Sandbox;
