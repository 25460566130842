import React from "react";
import WhyNeedUsThumb from "./Thumbnails/WhyNeedUsThumb"; // Import individual blog thumbnails

const BlogUI = () => {
  return (
    <section className="bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900">Our Blogs</h1>
          <p className="text-lg text-gray-700 mt-4">
            Explore insights, tips, and updates from the world of FireTable.
          </p>
        </div>

        {/* Blog Thumbnails Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Blog Thumbnails */}
          <WhyNeedUsThumb />
          {/* Add more blog thumbnails as needed */}
          {/* Example: <AnotherBlogThumb /> */}
        </div>
      </div>
    </section>
  );
};

export default BlogUI;
