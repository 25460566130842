import React from "react";

import wipImage from "../assets/wip-2.png"; // Adjust the path as per your assets folder

const WIP = () => {
  return (
    <div>
   
      <section className=" min-h-screen flex flex-col justify-center items-center text-center">
        <div className="max-w-4xl px-4">
          <img
            src={wipImage}
            alt="Work In Progress"
            className="w-72 h-auto mx-auto mb-8"
          />
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            We're Working On It!
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            This feature is currently under development. Check back soon for
            updates!
          </p>
          <a
            href="/"
            className="bg-primary text-white py-3 px-6 rounded-full font-semibold shadow-md hover:bg-secondary hover:shadow-lg transition duration-300"
          >
            Go Back Home
          </a>
        </div>
      </section>
    </div>
  );
};

export default WIP;
