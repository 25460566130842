import React, { useEffect, useState } from "react";

const PurchaseOrder = ({ cartItems, setCartItems }) => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    // Retrieve menu items from local storage
    const storedMenuItems =
      JSON.parse(localStorage.getItem("sandboxMenuItems")) || [];
    setMenuItems(storedMenuItems);
  }, []);

  const handleAddToCart = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    if (existingItem) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    } else {
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    }
  };

  const handleRemoveFromCart = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    if (existingItem && existingItem.quantity > 1) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        )
      );
    } else {
      setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
    }
  };

  const totalCost = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold text-primary mb-4">Menu</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {menuItems.length > 0 ? (
          menuItems.map((item) => (
            <div key={item.id} className="border rounded-lg p-4 shadow-sm">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-32 object-cover rounded-lg mb-2"
                />
              )}
              <h3 className="font-semibold text-lg text-gray-800 mb-1">
                {item.name}
              </h3>
              <p className="text-gray-600 mb-2">{item.description}</p>
              <p className="text-secondary font-bold">
                ${item.price.toFixed(2)}
              </p>
              <button
                onClick={() => handleAddToCart(item)}
                className="mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
              >
                Add to Cart
              </button>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No menu items available.</p>
        )}
      </div>

      {cartItems.length > 0 && (
        <div className="mt-8">
          <h3 className="text-xl font-bold text-secondary mb-4">Your Cart</h3>
          <ul className="space-y-4">
            {cartItems.map((item) => (
              <li
                key={item.id}
                className="flex justify-between items-center border p-4 rounded-lg shadow-sm"
              >
                <div>
                  <h4 className="text-lg font-semibold text-gray-800">
                    {item.name}
                  </h4>
                  <p className="text-gray-600 text-sm">
                    ${item.price.toFixed(2)} each
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleRemoveFromCart(item)}
                    className="w-8 h-8 bg-secondary text-white font-bold rounded-full hover:bg-accent1 transition"
                  >
                    -
                  </button>
                  <span className="text-lg font-semibold">{item.quantity}</span>
                  <button
                    onClick={() => handleAddToCart(item)}
                    className="w-8 h-8 bg-primary text-white font-bold rounded-full hover:bg-secondary transition"
                  >
                    +
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-4 font-bold text-lg">
            Total: ${totalCost.toFixed(2)}
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseOrder;
