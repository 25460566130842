import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const TermsAndConditions = () => {
  return (
    <div>
      {/* Navbar */}
      <Navbar />

      {/* Terms and Conditions Content */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">
            Terms and Conditions
          </h1>
          <p className="text-gray-700 text-lg leading-relaxed mb-8">
            Welcome to FireTable. By accessing or using our platform, you agree
            to comply with and be bound by the following terms and conditions
            ("Terms"). Please read these Terms carefully before using our
            services.
          </p>

          {/* General Terms */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              General Terms
            </h2>
            <ul className="list-disc list-inside text-gray-700 space-y-3">
              <li>
                FireTable is an online platform designed to streamline
                restaurant operations, including menu digitization, order
                management, and analytics.
              </li>
              <li>
                By using FireTable, you agree to provide accurate and truthful
                information during registration and usage.
              </li>
              <li>
                Unauthorized use of the platform, including but not limited to
                hacking, tampering, or reverse engineering, is strictly
                prohibited.
              </li>
            </ul>
          </div>

          {/* Limitations of Liability */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Limitations of Liability
            </h2>
            <p className="text-gray-700 leading-relaxed">
              FireTable is provided on an "as is" and "as available" basis. We
              make no guarantees regarding the uptime, reliability, or
              availability of our services. By using FireTable, you acknowledge
              that:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-3 mt-4">
              <li>
                FireTable is not liable for any damages, including but not
                limited to data loss, business interruption, or financial
                losses, incurred while using our platform.
              </li>
              <li>
                As FireTable is a working product still under development, we
                are not responsible for any errors, omissions, or technical
                issues that may arise.
              </li>
              <li>
                FireTable cannot be held responsible for the misuse of the
                platform or for third-party integrations.
              </li>
            </ul>
          </div>

          {/* Indemnity */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Indemnity
            </h2>
            <p className="text-gray-700 leading-relaxed">
              By using FireTable, you agree to indemnify, defend, and hold
              harmless FireTable, its employees, affiliates, and partners from
              any claims, damages, or liabilities arising from your use of the
              platform.
            </p>
          </div>

          {/* Governing Law */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Governing Law
            </h2>
            <p className="text-gray-700 leading-relaxed">
              These Terms and Conditions are governed by the laws of Singapore.
              Any disputes arising from these Terms will be resolved in the
              courts of Singapore.
            </p>
          </div>

          {/* No Warranty Disclaimer */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              No Warranty Disclaimer
            </h2>
            <p className="text-gray-700 leading-relaxed">
              FireTable disclaims all warranties, whether express or implied,
              including but not limited to merchantability, fitness for a
              particular purpose, and non-infringement.
            </p>
          </div>

          {/* Contact Information */}
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Contact Information
            </h2>
            <p className="text-gray-700 leading-relaxed">
              If you have any questions about these Terms, please contact us at:
            </p>
            <p className="text-gray-700 mt-2">
              Email:{" "}
              <a href="mailto:support@firetable.com" className="text-primary">
                support@firetable.com
              </a>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
