import React from "react";
import {
  FaUserPlus,
  FaUtensils,
  FaQrcode,
  FaCheckCircle,
  FaChartBar,
} from "react-icons/fa";

const Hiw = () => {
  const steps = [
    {
      title: "Sign Up",
      description: (
        <p>
          Create an account and set up
          <br /> your restaurant profile in minutes.
        </p>
      ),
      icon: <FaUserPlus size={24} className="text-secondary" />,
    },
    {
      title: "Create Your Menu",
      description: (
        <p>
          Upload your menu items with categories,
          <br /> descriptions, and prices.
        </p>
      ),
      icon: <FaUtensils size={24} className="text-secondary" />,
    },
    {
      title: "Generate QR Codes",
      description: (
        <p>
          Easily generate QR codes
          <br /> for your tables.
        </p>
      ),
      icon: <FaQrcode size={24} className="text-secondary" />,
    },
    {
      title: "Start Receiving Orders",
      description: (
        <p>
          Customers scan the QR code,
          <br /> browse the menu, and place orders instantly.
        </p>
      ),
      icon: <FaCheckCircle size={24} className="text-secondary" />,
    },
    {
      title: "Track and Manage",
      description: (
        <p>
          Monitor orders in real-time
          <br /> and manage them from your dashboard.
        </p>
      ),
      icon: <FaChartBar size={24} className="text-secondary" />,
    },
  ];

  return (
    <section id="how-it-works" className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl">
            How It Works
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Start your journey with FireTable in just a few simple steps.
          </p>
        </div>

        {/* Steps Grid */}
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className="group bg-white rounded-lg shadow-md hover:shadow-xl transition-all duration-300 p-8 border-l-4 border-secondary transform hover:-translate-y-2"
            >
              {/* Icon and Title */}
              <div className="flex items-center space-x-3">
                <div>{step.icon}</div>
                <h3 className="text-lg font-medium text-gray-900">
                  {step.title}
                </h3>
              </div>
              {/* Description */}
              <p className="mt-4 text-sm text-gray-600 ">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hiw;
