import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid"; // For generating unique IDs

const AddMenuItem = ({ onAddItem }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create the new menu item
    const newItem = {
      id: uuidv4(), // Generate a unique ID
      name,
      description,
      price: parseFloat(price),
      category,
      image: image ? URL.createObjectURL(image) : null, // Convert the image file to a URL
    };

    // Get existing menu items from localStorage
    const existingItems =
      JSON.parse(localStorage.getItem("sandboxMenuItems")) || [];
    const updatedItems = [...existingItems, newItem];

    // Save updated items back to localStorage
    localStorage.setItem("sandboxMenuItems", JSON.stringify(updatedItems));

    // Reset form fields
    setName("");
    setDescription("");
    setPrice("");
    setCategory("");
    setImage(null);

    // Trigger the parent callback to refresh the menu list
    if (onAddItem) {
      onAddItem();
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold text-primary mb-4">Menu Creator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-semibold text-gray-700">
            Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full border rounded-lg p-2 mt-1"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-semibold text-gray-700">
            Description
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full border rounded-lg p-2 mt-1"
          />
        </div>
        <div>
          <label className="block text-sm font-semibold text-gray-700">
            Price
          </label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full border rounded-lg p-2 mt-1"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-semibold text-gray-700">
            Category
          </label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full border rounded-lg p-2 mt-1"
          />
        </div>
        <div>
          <label className="block text-sm font-semibold text-gray-700">
            Image
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            className="w-full p-2 border rounded-lg mt-1"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-secondary text-white py-2 rounded-lg font-semibold hover:bg-accent1 transition"
        >
          Add Item
        </button>
      </form>
    </div>
  );
};

export default AddMenuItem;
