import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation
import Navbar from "./components/Navbar";

const NotFound = () => {
  return (
    <div>
      <Navbar />
      <section className="bg-gray-50 min-h-screen flex flex-col justify-center items-center text-center">
        <div className="max-w-4xl px-4">
          <h1 className="text-6xl font-bold text-primary mb-4">404</h1>
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">
            Page Not Found
          </h2>
          <p className="text-lg text-gray-600 mb-8">
            Oops! The page you’re looking for doesn’t exist. It might have been
            moved or deleted.
          </p>
          <Link
            to="/"
            className="inline-block bg-primary text-white py-3 px-6 rounded-lg font-semibold shadow-md hover:bg-secondary hover:shadow-lg transition duration-300"
          >
            Go Back Home
          </Link>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
