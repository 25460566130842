import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Install js-cookie: npm install js-cookie

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(true); // To toggle banner visibility
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the cookieAccepted token exists
    const cookieAccepted = Cookies.get("cookieAccepted");
    if (cookieAccepted === "true") {
      setIsVisible(false);
    }
  }, []);

  const handleAccept = () => {
    // Set the cookie token
    Cookies.set("cookieAccepted", "true", { expires: 365 }); // Valid for 1 year
    setIsVisible(false);
  };

  const handleReject = () => {
    // Redirect to Google if cookies are rejected
    window.location.href = "https://www.google.com";
  };

  if (!isVisible) return null; // Hide banner if accepted

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 bg-opacity-10 backdrop-blur-md text-gray-900 py-4 px-6 z-50 shadow-lg">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
        {/* Short Message */}
        <p className="text-sm sm:text-base mb-4 sm:mb-0">
          We collect cookies to enhance your experience.
        </p>

        {/* Buttons */}
        <div className="flex space-x-4">
          <button
            onClick={handleAccept}
            className="bg-secondary text-white py-2 px-4 rounded-lg text-sm sm:text-base font-semibold hover:bg-accent1 transition duration-300"
          >
            Accept
          </button>
          <button
            onClick={handleReject}
            className="bg-gray-100 text-primary py-2 px-4 rounded-lg text-sm sm:text-base font-semibold hover:bg-gray-200 transition duration-300"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
