import React from "react";


const RlseNt1 = () => {
  return (
    <div>
      

      {/* Release Note Content */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-8">
            Release Notes: Version 1.0
          </h1>
          <p className="text-lg text-gray-700 leading-relaxed mb-8 text-center">
            Welcome to the first official release of FireTable! Here’s everything that’s included in version 1.0.
          </p>

          <div className="space-y-8">
            <div>
              <h2 className="text-2xl font-semibold text-primary mb-4">
                Features
              </h2>
              <ul className="list-disc list-inside text-gray-700 space-y-3">
                <li>Digital menu creation with QR code integration for customers.</li>
                <li>Real-time order tracking for seamless restaurant operations.</li>
                <li>Analytics dashboard to track sales, order trends, and popular items.</li>
                <li>Eco-friendly, paperless solution for menu management.</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-primary mb-4">
                Improvements
              </h2>
              <ul className="list-disc list-inside text-gray-700 space-y-3">
                <li>Enhanced user interface for better navigation and usability.</li>
                <li>Optimized backend to ensure faster performance and stability.</li>
                <li>Improved QR code generation for better compatibility with mobile devices.</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-primary mb-4">
                Known Issues
              </h2>
              <p className="text-gray-700">
                As this is our first release, there may be minor bugs or usability issues. We’re actively working on resolving them in the next update. If you encounter any issues, please reach out to our support team.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-primary mb-4">
                What’s Next?
              </h2>
              <p className="text-gray-700">
                We’re already working on exciting new features like POS integration, advanced customer insights, and more! Stay tuned for future updates.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RlseNt1;
